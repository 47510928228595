import axios from "axios";
import { toast } from "react-toastify";

// const endpoint = process.env.REACT_APP_URL;

const uri = axios.create({
  baseURL: `${process.env.REACT_APP_URL as string}`,
});

export const useApi = () => ({
  signIn: async (user: string, pass: string) => {
    try {
      const { data } = await uri.post("/psslogin", { user, pass });
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  },
  signUp: async (user: string, passEmail: string, newPass: string) => {
    try {
      const { data } = await uri.post("/pssatuesq", {
        user,
        passEmail,
        newPass,
      });
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  },
  firstLogin: async (user: string, pass: string, newPass: string) => {
    try {
      const { data } = await uri.post("/psslogin", { user, pass, newPass });
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  },
  forgetPass: async (user: string, number: string) => {
    try {
      const { data } = await uri.post("/PssEsqLg", { user, number });
      return data;
    } catch (error: any) {
      toast.error(error.message);
    }
  },
  getAllTables: async (ID: string) => {
    const { data } = await uri.get(`/gettitwkf?ccodaprv=${ID}`);
    return data;
  },
});
