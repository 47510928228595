import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  .top {
    padding: 1rem;
  }
  .chart {
    height: 60%;
  }
`;
