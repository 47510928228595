export const light = {
  title: "light",
  colors: {
    background: "#F3F3F4",
    header: "#FFFFFF",
    sidebar: "#FFFFFF",
    text: "#69647D",
    tableText: "#333",
    modalH3: "#444",
  },
};
