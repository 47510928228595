export const dark = {
  title: "dark",
  colors: {
    background: "#161D31",
    header: "#283046",
    sidebar: "#283046",
    text: "#D0D2D6",
    tableText: "#FFFF",
    modalH3: "#aaaa",
  },
};
